<template>
  <div class="comment-form-container">
    <div class="back_button_container">
      <router-link class="button" :to="{ name: 'comments' }">{{ $t('router.comments') }}</router-link>
    </div>
    <form id="comment-form" ref="commentForm" @submit="submit">
      <div v-if="errors.length">
        <b>{{ $t('form.validation.invalid') }}</b>
        <ul>
          <li class="error" v-for="(error, i) in errors" :key="i">{{ error }}</li>
        </ul>
      </div>

      <div>
        <input v-model="comment.name" type="text" name="name" :placeholder="$t('form.name.placeholder')" required />
      </div>

      <div>
        <input v-model="comment.email" type="email" name="email" :placeholder="$t('form.email.placeholder')" required />
      </div>

      <div>
        <input v-model="comment.title" type="text" name="title" :placeholder="$t('form.title.placeholder')" />
      </div>

      <div class="comment_area">
        <label>{{  $t('form.comment.label') }}</label>
        <textarea v-model="comment.text" name="text"></textarea>
      </div>

      <div>
        <button type="submit">{{ $t('form.submit.text') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { userLoader } from '../../mixins/userLoader'

export default {
  name: 'CommentForm',
  mixins: [userLoader],
  data: function () {
    return {
      errors: [],
      comment: {
        name: null,
        email: null,
        title: null,
        text: null
      }
    }
  },
  mounted () {
    this.comment.name = this.userName
    this.comment.email = this.userEmail
  },
  methods: {
    submit: function (e) {
      e.preventDefault()
      this.errors = []

      if (!this.comment.name) this.errors.push(this.$t('form.name.empty'))

      if (!this.comment.email) this.errors.push(this.$t('form.email.empty'))
      else if (!this.validateEmail(this.comment.email)) { this.errors.push(this.$t('form.email.invalid')) }

      if (!this.comment.text) this.errors.push(this.$t('form.comment.empty'))

      if (!this.errors.length) {
        this.$store.dispatch('comments/createComment', this.comment)
          .then(() => {
            this.$refs.commentForm.reset()
            this.comment.title = null
            this.comment.text = null

            this.$store.commit('newNotification', {
              text: 'comment.new.successful',
              button: 'system.error.dismiss',
              timeout: 10000
            })

            this.$router.push({ name: 'comments' })
          })
          .catch(() => {
            this.$store.commit('newNotification', {
              text: 'comment.new.failed',
              button: 'system.error.dismiss',
              timeout: 10000
            })
          })
      }
    },

    validateEmail: function (email) {
      var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email)
    }
  }
}
</script>
<style lang="scss" scoped>
  .comment-form-container{
    width: 100%;
    margin: 0 auto;
    max-width: $maxContainerWidth;
  }

  .back_button_container {
    margin: $bodyMargin;
    text-align: center;
    border-top:3px solid $pink;
    padding: $buttonMargin 0 0 0;
  }

  #comment-form{
    margin: 0 auto;
    max-width: 300px;

    @media screen and($mobilePortrait) {
      max-width: 400px;
    }
    @media screen and($tabletPortrait) {
      max-width: 500px;
    }

    input{
      max-width: 100%;
    }
  }

  .comment_area{
    margin-top: 1rem;
  }
</style>
