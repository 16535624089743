import axiosApi from 'axios'

const axios = axiosApi.create({
  baseURL: '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

/**
 * work around as config params are ignored in axios 19.0 - 19.2
 */
// axios.interceptors.request.use(config => {
//     config.params = {
//         token: '7a8a8d9612b70ea076c7a1c83c81ac',
//         ...config.params,
//     }
//     return config;
// });

export default axios
