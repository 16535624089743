<template>
    <div class="locality-form-container">
        <div class="back_button_container">
            <router-link class="button" :to="{ name: 'localities' }">{{ $t('router.localities') }}</router-link>
        </div>
        <form id="locality-form" ref="localityForm" @submit="submit">
            <div v-if="errors.length">
                <b>{{ $t('form.validation.invalid') }}</b>
                <ul>
                    <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                </ul>
            </div>
            <div>
                <input v-model="locality.name" type="text" name="name" :placeholder="$t('form.name.placeholder')" required/>
            </div>
            <div>
                <input v-model="locality.email" type="email" name="email" :placeholder="$t('form.email.placeholder')" required/>
            </div>
            <div class="file_area">
                <input class="nagon-file-input" id="file" type="file" accept="image/*" name="image" ref="image" v-on:change="imageUpload" required/>
                <div class="fake_file">
                    <label for="file" class="button">{{ $t('form.image.label') }}</label>
                    <input class="normal" id="ShowFileName" readonly>
                </div>
            </div>
            <div class="comment_area">
                <label>{{ $t('form.text.label') }}</label>
                <textarea v-model="locality.text" required></textarea>
            </div>
            <div class="mapbox_container">
                <label>{{$t('form.coordinates.label')}}</label>
                <br>

                <button class="gps_button" v-if="autoGeoLocation && autoGeoLocationAccessible" @click="commitCoordinates">{{ $t('map.commit_locality') }}</button>
                <br>
                <input class="lat" v-model="locality.lat" type="text" readonly required>
                <input class="lng" v-model="locality.lng" type="text" readonly required>
                <div class="form_location">
                    <div class="localitypicker-label">{{ $t('map.select_locality')}}</div>
                    <div class="localitypicker-label">{{ $t('map.select_locality_2')}}</div>
                    <LocationPicker></LocationPicker>
                </div>
            </div>
            <div>
                <button type="submit">{{ $t('form.record.text') }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import { userLoader } from '../../mixins/userLoader'
import LocationPicker from '../Map/LocationPicker'

export default {
  name: 'LocalityForm',
  components: { LocationPicker },
  mixins: [userLoader],
  data: function () {
    return {
      errors: [],
      autoGeoLocation: true,
      autoGeoLocationAccessible: true,
      positionWatcherId: null,
      locality: {
        name: null,
        email: null,
        text: null,
        lat: null,
        lng: null
      },
      curPos: {
        lat: null,
        lng: null
      },
      file: null
    }
  },
  mounted () {
    this.initGeoWatcher()

    this.locality.name = this.userName
    this.locality.email = this.userEmail
  },

  methods: {
    initGeoWatcher () {
      if (navigator.geolocation) {
        // keep track of the geo location
        this.positionWatcherId = navigator.geolocation.watchPosition((position) => {
          this.autoGeoLocation = true
          this.autoGeoLocationAccessible = true

          // set initial position
          if (this.curPos.lat === null && this.curPos.lng === null) {
            this.$store.commit('gps/setCurPosition', { lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) })
          }

          this.curPos.lat = position.coords.latitude
          this.curPos.lng = position.coords.longitude
        },
        (e) => {
          // error code 1 = user declined to use geo location
          if (e.code === 1) {
            this.autoGeoLocation = false
            this.autoGeoLocationAccessible = false
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0
        })
      } else {
        this.autoGeoLocation = false
        this.autoGeoLocationAccessible = false
      }
    },

    enableAutoLocation (e) {
      e.preventDefault()

      this.locality.lat = null
      this.locality.lng = null

      this.autoGeoLocation = true
      this.initGeoWatcher()
    },

    disableAutoLocation (e) {
      e.preventDefault()
      this.autoGeoLocation = false

      this.locality.lat = null
      this.locality.lng = null

      if (navigator.geolocation) {
        navigator.geolocation.clearWatch(this.positionWatcherId)
      } else {
        this.autoGeoLocationAccessible = false
      }
    },

    imageUpload () {
      this.file = this.$refs.image.files[0]
      document.getElementById('ShowFileName').value = this.file.name
    },

    commitCoordinates (e) {
      e.preventDefault()

      if (!this.autoGeoLocationAccessible || isNaN(this.curPos.lat) || isNaN(this.curPos.lng)) {
        return
      }

      this.$store.commit('gps/setCurPosition', { lat: this.curPos.lat, lng: this.curPos.lng })

      this.locality.lat = this.curPos.lat
      this.locality.lng = this.curPos.lng
    },

    submit (e) {
      e.preventDefault()

      this.errors = []

      if (!this.locality.name) this.errors.push(this.$t('form.name.empty'))

      if (!this.locality.email) this.errors.push(this.$t('form.email.empty'))
      else if (!this.validateEmail(this.locality.email)) { this.errors.push(this.$t('form.email.invalid')) }

      if (!this.locality.text) this.errors.push(this.$t('form.text.empty'))

      if (!this.locality.lat || !isFinite(this.locality.lat) || Math.abs(this.locality.lat) > 90) this.errors.push(this.$t('form.lat.invalid'))

      if (!this.locality.lng || !isFinite(this.locality.lng) || Math.abs(this.locality.lng) > 180) this.errors.push(this.$t('form.lng.invalid'))

      let formData = new FormData()

      formData.set('name', this.locality.name)
      formData.set('email', this.locality.email)
      formData.set('text', this.locality.text)
      formData.set('lat', this.locality.lat)
      formData.set('long', this.locality.lng)
      formData.append('image', this.file)

      if (!this.errors.length) {
        this.$store.dispatch('localities/createLocality', formData)
          .then((response) => {
            formData = null
            this.$refs.localityForm.reset()
            this.locality.text = ''

            this.$store.commit('newNotification', {
              text: 'locality.new.successful',
              button: 'system.error.dismiss',
              timeout: 10000
            })
            this.$router.push({ name: 'localities' })
          })
          .catch(() => {
            this.$store.commit('newNotification', {
              text: 'locality.new.failed',
              button: 'system.error.dismiss',
              timeout: 10000
            })
          })
      }
    },
    validateEmail (email) {
      var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email)
    }
  }
  // watch: {
  //   autoGeoLocation () {
  //     if (!this.autoGeoLocation) {
  //       if (navigator.geolocation) {
  //         navigator.geolocation.clearWatch(this.positionWatcherId)
  //       }
  //     } else {
  //       this.initGeoWatcher()
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>

    .locality-form-container {
        width: 100%;
        margin: 0 auto;
        max-width: $maxContainerWidth;
    }

    .back_button_container {
        margin: $bodyMargin;
        text-align: center;
        border-top: 3px solid $pink;
        padding: $buttonMargin 0 0 0;
    }

    button {
        cursor: pointer;
    }

    #locality-form {
        margin: 0 auto;
        max-width: 300px;

        @media screen and($mobilePortrait) {
            max-width: 400px;
        }
        @media screen and($tabletPortrait) {
            max-width: 500px;
        }

        input {
            max-width: 100%;
        }
    }

    .file_area {
        position: relative;
        padding: 1rem 0;

        .nagon-file-input {
            position: relative;
            opacity: 0;
            z-index: 2;
        }

        .fake_file {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    .file_area {
        text-align: center;
        cursor: pointer;
    }

    input {
        &.lat,
        &.lng {
            width: 45%;
        }

        &.lng {
            float: right;
        }
    }

    .gps_button {
        margin-bottom: 3px;
    }

    .file_area,
    .comment_area {
        margin-top: 1rem;
    }

    .form_location {
        margin-top: 16px;

        .localitypicker-label {
            margin-bottom: 4px;
            font-size:12px;
        }
    }
</style>
