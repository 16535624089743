import Vue from 'vue'
import VueRouter from 'vue-router'
import Localities from '../views/Localities'
import Comments from '../views/Comments'
import Faq from '../views/Faq'
import LocalityForm from '../components/Locality/LocalityForm'
import LocalityList from '../components/Locality/LocalityList'
import CommentForm from '../components/Comment/CommentForm'
import CommentList from '../components/Comment/CommentList'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: '/app',
  routes: [
    {
      path: '',
      component: Localities,
      children: [
        {
          path: 'create',
          name: 'create-locality',
          component: LocalityForm
        },
        {
          path: '',
          name: 'localities',
          component: LocalityList
        }
      ]
    },
    {
      path: '/comments',
      component: Comments,
      children: [
        {
          path: 'create',
          name: 'create-comment',
          component: CommentForm
        },
        {
          path: '',
          name: 'comments',
          component: CommentList
        }
      ]
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq
    }
  ]
})
