import { mapGetters } from 'vuex'

export const localityLoader = {
  data: function () {
    return {
      localities: undefined
    }
  },

  computed: {
    ...mapGetters({
      isInitialised: 'localities/isInitialised',
      getAllLocalities: 'localities/getAllLocalities'
    })
  },

  created () {
    this.loadRecords()
  },

  methods: {
    loadRecords () {
      if (this.isInitialised) {
        this.localities = this.getAllLocalities
      } else {
        this.$store.dispatch('localities/loadLocalities').then(() => {
          this.localities = this.getAllLocalities
        })
      }
    }
  }
}
