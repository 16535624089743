<template>
  <div class="max_width">
    <section class="faq_section">
      <h2>FAQ – Wildrosen</h2>
      <details>
        <summary>Warum muss ich Name und E-Mail angeben?</summary>
        <div class="content">
          <p>
            So können wir dich kontaktieren, falls du etwas besonders Interessantes oder Merkwürdiges gemeldet hast.
            Ausserdem werden alle, die sich an der Meldeaktion beteiligt haben, per Mail zu einer öffentlichen
            Schlussveranstaltung eingeladen. Dein Mail wird für nichts anderes verwendet und nicht an Dritte
            weitergegeben!
          </p>
        </div>
      </details>
      <details>
        <summary>Achtung: WLAN kann die Position stören!</summary>
        <div class="content">

          <p>
            Wenn dein Handy mit einem WLAN verbunden ist, kann das einen störenden Einfluss auf das Bestimmen deiner GPS
            Koordinaten haben. Wenn du beim direkten Melden draussen sicher sein willst, dass deine Position richtig
            angegeben wird, dann schalte beim Handy die WLAN-Verbindung aus (unter „Einstellungen“).
          </p>
        </div>
      </details>
      <details>
        <summary>Wie melde ich eine Wildrose, wenn ich draussen davor stehe?</summary>
        <div class="content">
          <ol>
            <li>Gehe auf der Startseite auf „Wildrosen melden“<br>
            <small>Wenn du gefragt wirst, ob die Website deinen aktuellen Ort
              verwenden darf, dann drücke „Erlauben“</small>
            </li>
            <li>Gib Name und E-Mail an. Falls du das schon einmal gemacht hast,
              erscheint unten ein Vorschlag, den du anklicken kannst. So musst du nicht alles jedesmal frisch eingeben.</li>
            <li>Wähle dann bei „Foto oder Bild auswählen“ die oberste Option „Foto aufnehmen“. Mach ein Foto und entscheide dich
              entweder für „Foto benutzen“ oder mach ein noch besseres Bild</li>
            <li> Gib dann den Namen des Fundortes an</li>
            <li> Die Position kann mittels klick auf die Karte oder mittels Koordinaten übernehmen (Nur bei aktiviertem GPS) bestimmt werden. Danach kannst du mittels verschieben des Markers die Position noch verfeinern</li>
            <li>Du musst also nur noch „Melden“ drücken</li>
          </ol>
        </div>
      </details>
      <details>
        <summary>Wie melde ich zuhause eine Wildrose, die ich unterwegs fotografiert habe?</summary>
        <div class="content">
          <ol>
            <li>Gehe auf der Startseite auf „Wildrosen melden“</li>
            <li>Gib Name und E-Mail an. Falls du das schon einmal gemacht hast, erscheint unten ein Vorschlag, den du anklicken kannst. So musst du nicht alles jedesmal frisch eingeben.</li>
          <li>Wähle dann bei „Foto oder Bild auswählen“ die Option „Fotomediathek“. Tippe das gewünschte Foto an und dann oben auf „fertig“</li>
              <li>
                  Gib dann den Namen des Fundortes an
              </li>
            <li> Die Position kann mittels klick auf die Karte bestimmt werden. Danach kannst du mittels verschieben des Markers die Position noch verfeinern</li>
            <li> Wenn das Symbol am richtigen Ort steht, drückst du unten auf „Melden“
            </li>

          </ol>
        </div>
      </details>
    </section>
    <section class="impressum">
      <h2>IMPRESSUM</h2>
      <h4>Verwantwortung und Inhalt</h4>
      <p>
        <strong>Naturforschende Gesellschaft Obwalden und Nidwalden (NAGON)</strong><br>
        Kägiswilerstrasse 13 <br>
        6064 Kerns <br>
        <a href="https://www.nagon.ch">www.nagon.ch</a>
      </p>
      <h4>Umsetzung PWA</h4>
      <p>
        <strong>DOCONO KLG</strong><br>
        Alpnach Dorf<br>
        <a href="https://www.docono.io">www.docono.io</a><br>
      </p>
    </section>
    <section class="disclaimer">
      <h2>NUTZUNGSBEDINGUNGEN</h2>
      <h4>Haftung</h4>
      <p>
        Obwohl die NAGON mit aller Sorgfalt auf die Richtigkeit der veröffentlichten Informationen achten, kann
        hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit dieser
        Informationen keine Gewährleistung übernommen werden.
      </p>
      <p>
        Die NAGON behaltet sich ausdrücklich vor, jederzeit Inhalte ohne Ankündigung ganz oder teilweise zu
        ändern, zu löschen oder zeitweise nicht zu veröffentlichen.
      </p>
      <p>
        Haftungsansprüche gegen die NAGON wegen Schäden materieller oder immaterieller Art, welche aus dem
        Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung
        oder durch technische Störungen entstanden sind, werden ausgeschlossen.
      </p>
      <h4>Verweise und Links</h4>
      <p>
        Verweise und Links auf Webseiten Dritter liegen ausserhalb des Verantwortungsbereichs der NAGON. Der
        Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin. Die
        NAGON erklärt ausdrücklich, dass sie keinerlei Einfluss auf die Gestaltung, den Inhalt und die Angebote
        der verknüpften Seiten haben. Informationen und Dienstleistungen von verknüpften Webseiten liegen vollumfänglich
        in der Verantwortung des jeweiligen Dritten.
      </p>
      <p>
        Es wird jegliche Verantwortung für solche Webseiten abgelehnt.
      </p>
    </section>
    <section class="datasecurity">
      <h2>DATENSCHUTZ</h2>
      <p>
        Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des
        Bundes hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen
        Daten. Die NAGON haltet diese Bestimmungen ein. Persönliche Daten werden streng vertraulich behandelt und
        weder an Dritte verkauft noch weiter gegeben.
      </p>

      <p>
        In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor
        fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
      </p>

      <p>
        Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit,
        Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser.
      </p>

      <p>
        Diese Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind,
        anhand
        derer die NAGON ihr Angebot entsprechend verbessern können.
      </p>

      <p>
        Bei der freiwilligen Kontaktaufnahme wird Ihre Email-Adresse in eine separate Datenbank aufgenommen, welche
        nicht
        mit den anonymen Logfiles verknüpft wird. Sie haben jederzeit die Möglichkeit, Ihre Registrierung wieder
        rückgängig zu machen.
      </p>
    </section>
    <section class="copyright">
      <h2>Copyright</h2>
      <p>
        Copyright, Naturforschende Gesellschaft Obwalden und Nidwalden (NAGON).
      </p>
      <p>
        Die auf den Websites der NAGON enthaltenen Informationen werden der Öffentlichkeit zugänglich gemacht.
        Durch das Herunterladen oder Kopieren von Inhalten, Bildern, Fotos oder anderen Dateien werden keinerlei Rechte
        bezüglich der Inhalte übertragen.
      </p>
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf den Websites oder Apps der
        NAGON gehören ausschliesslich diesen oder den speziell genannten Rechtsinhabern. Für die Reproduktion
        jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus einzuholen.
      </p>
    </section>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Faq'
}
</script>

<style lang="scss" scoped>
  details {
    font-size: 1rem;
    font-family: $regularFont;
    margin: 1rem 0;

    .content {
      padding: 0.5rem;
      opacity: 0;
      transition: opacity 200ms ease-in;
    }

    &[open] {
      .content {
        opacity: 1;
      }
    }

    summary {
      font-family: $boldFont;
      font-size: 1rem;
      cursor: pointer;
      outline: none !important;

      @media screen and ($mobilePortrait){
        font-size: 1.2rem;
      }

      &::marker {
        width: 30px;
        color: $pink;
      }
    }
  }

  h2 {
    margin-top: 2rem;
    border-top: 2px solid $pink;
    padding-top: 2rem;
  }

  strong {
    font-family: $boldFont;
    font-weight: 400;
  }

  h4 {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    font-family: $boldFont;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 1rem 0;
  }

  a {
    color: inherit;
  }

  .max_width {
    max-width: 1024px;
    margin: 0 auto;

    section{
      margin: 0 1rem;
    }
  }
</style>
