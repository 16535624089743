import { mapGetters } from 'vuex'

export const userLoader = {
  computed: {
    ...mapGetters(['user']),
    userName () {
      return this.user.name
    },
    userEmail () {
      return this.user.email
    }
  }
}
