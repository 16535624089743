<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      marker: null,
      icon: {
        url: require('@/assets/Rose_Marker.svg'),
        scaledSize: new this.google.maps.Size(40, 40), // scaled size
        origin: new this.google.maps.Point(0, 0), // origin
        anchor: new this.google.maps.Point(20, 40) // anchor
      }
    }
  },
  computed: {
    ...mapGetters({
      curPosition: 'gps/getCurPosition'
    })
  },
  render () {
    return ''
  },

  mounted () {
    this.google.maps.event.addListener(this.map, 'click', function (e) {
      const curPosition = e.latLng

      this.map.setCenter(curPosition)

      if (!this.marker) {
        this.marker = new this.google.maps.Marker({
          position: curPosition,
          map: this.map,
          icon: this.icon,
          draggable: true
        })

        this.registerMarkerDragend()
      } else {
        this.marker.setPosition(curPosition)
      }

      this.$emit('updateLocation', { lat: curPosition.lat(), lng: curPosition.lng() })
    }.bind(this))
  },

  methods: {
    registerMarkerDragend () {
      this.google.maps.event.addListener(this.marker, 'dragend', function (e) {
        this.map.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })
        this.$emit('updateLocation', { lat: e.latLng.lat(), lng: e.latLng.lng() })
      }.bind(this))
    },

    setCurPosition () {
      if (this.curPosition === null) {
        return
      }

      this.$emit('updateLocation', { lat: this.curPosition.lat, lng: this.curPosition.lng })

      this.map.setCenter(this.curPosition)

      if (!this.marker) {
        this.marker = new this.google.maps.Marker({
          position: this.curPosition,
          map: this.map,
          icon: this.icon,
          draggable: true
        })

        this.registerMarkerDragend()
      } else {
        this.marker.setPosition(this.curPosition)
      }
    }
  },

  watch: {
    curPosition: function () {
      this.setCurPosition()
    }
  }
}
</script>
