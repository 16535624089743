<template>
    <section class="locality_section">
          <header class="locality_header">
            <p class="lead">
              
 Das Wildrosen-Meldeprojekt der Naturforschenden Gesellschaft Ob- und Nidwalden ist abgeschlossen. Es wurden über 800 Wildrosenstandorte gemeldet (s. Karte). Herzlichen Dank allen Melder*innen!
            </p>
        </header>
      <div class="add_locality_container">
        <div v-else>
            {{ $t('locality.offline') }}
        </div>
      </div>
        <div class="map_container">
          <div v-if="isInitialised">
            <!--            <Locality v-for="(locality, i) in localities" :key="i" :locality="locality"></Locality>-->
              <LocalityMap></LocalityMap>
              <LocalityInfo></LocalityInfo>
          </div>
        </div>
    </section>
</template>

<script>
import { localityLoader } from '../../mixins/localityLoader'
// import Locality from './Locality'
import LocalityMap from './LocalityMap'
import LocalityInfo from './LocalityInfo'
import { mapGetters } from 'vuex'

export default {
  name: 'LocalityList',
  components: { LocalityInfo, LocalityMap },
  mixins: [localityLoader],
  computed: {
    ...mapGetters(['isOnline'])
  }
}
</script>

<style lang="scss" scoped>
  .add_locality_container,
  .map_container{
    margin: $bodyMargin;
    position: relative;
  }

  .add_locality_container{
    text-align: center;
    border-top:3px solid $pink;
    padding: $buttonMargin 0 0 0;
  }

  .locality_header{
    margin: $bodyMargin;
  }
</style>
