<template>
    <GoogleMapLoader
            :mapConfig="mapConfig"
    >
        <template slot-scope="{ google, map }">
            <GoogleMapMarkerPicker
                    :google="google"
                    :map="map"
                    @updateLocation="updateLocation"
                />
        </template>
    </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from './GoogleMapLoader'
import GoogleMapMarkerPicker from './GoogleMapMarkerPicker'
import { mapSettings } from '../../config'

export default {
  name: 'LocationPicker',
  components: { GoogleMapMarkerPicker, GoogleMapLoader },
  computed: {
    mapConfig () {
      return {
        ...mapSettings
      }
    }
  },
  methods: {
    updateLocation: function (location) {
      this.$parent.locality.lat = location.lat
      this.$parent.locality.lng = location.lng
    }
  }
}
</script>
