<script>
export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    bounds: {
      type: Object
    },
    locality: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      marker: null,
      icon: {
        url: require('@/assets/Rose_Marker.svg'),
        scaledSize: new this.google.maps.Size(40, 40), // scaled size
        origin: new this.google.maps.Point(0, 0), // origin
        anchor: new this.google.maps.Point(20, 40) // anchor
      }
    }
  },
  render () {
    return ''
  },

  mounted () {
    const location = { lat: parseFloat(this.locality.location.lat), lng: parseFloat(this.locality.location.lng) }
    /* eslint-disable no-new */
    this.marker = new this.google.maps.Marker({
      position: location,
      locality: this.locality,
      map: this.map,
      icon: this.icon
    })

    this.marker.addListener('click', () => { this.$emit('openLocality', this.locality.id) })

    this.$parent.markers.push(this.marker)

    if (this.bounds) {
      this.bounds.extend(location)

      this.map.fitBounds(this.bounds)
    }
  }
}
</script>
