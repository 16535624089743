<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Record',
  computed: {
    ...mapGetters(['isOnline'])
  }
}
</script>
