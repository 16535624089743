/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from './store'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: process.env.BASE_URL },

    updated (registration) {
      store.commit('newNotification', {
        cancel: true,
        text: 'system.update.text',
        button: 'system.update.execute',
        onClick: () => {
          registration.waiting.postMessage({ action: 'skipWaiting' })
          location.reload()
        }
      })
    },

    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
