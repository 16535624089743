import Vue from 'vue'
import Vuex from 'vuex'
import localities from './modules/localities'
import comments from './modules/comments'
import gps from './modules/gps'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    localities,
    comments,
    gps
  },
  state: {
    isOnline: false,
    readyToUpdate: false,
    loadingQueue: [],
    notification: {
      show: false,
      cancel: true,
      text: null,
      button: null,
      onClick: null,
      timeout: 0
    },
    user: JSON.parse(localStorage.getItem('user_data')) || {}
  },
  getters: {
    isOnline: state => {
      return state.isOnline
    },

    isLoading: state => {
      return state.loadingQueue.length > 0
    },

    hasUpdate: state => {
      return state.readyToUpdate
    },

    notification: state => {
      return state.notification
    },

    user: state => {
      return state.user
    }
  },
  mutations: {
    initialisation (state) {

    },

    onlineStatus (state, status) {
      state.isOnline = status
    },

    readyToUpdate (state, status) {
      state.readyToUpdate = status
    },

    newNotification (state, notification) {
      state.notification = Object.assign({
        show: true,
        cancel: false,
        text: null,
        button: null,
        onClick: null,
        timeout: 0
      }, notification)
    },

    setUser (state, user) {
      state.user = Object.assign(state.user, user)
      localStorage.setItem('user_data', JSON.stringify(user))
      // localStorage.setItem('user_data', user)
    },

    /**
         * add module to loading queue
         * @param state
         * @param module
         */
    addToLoadingQueue (state, module) {
      if (state.loadingQueue.indexOf(module) <= -1) { state.loadingQueue.push(module) }
    },

    /**
         * remove module from loading queue
         * @param state
         * @param module
         */
    removeFromLoadingQueue (state, module) {
      const pos = state.loadingQueue.indexOf(module)

      if (pos > -1) state.loadingQueue.splice(pos, 1)
    },

    flushLoadingQueue (state) {
      state.loadingQueue = []
    }
  },
  actions: {},

  strict: debug
})
