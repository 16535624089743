<template>
    <main>
      <div class="add_comment_container">
        <div v-if="isOnline">
            <router-link class="button" :to="{ name: 'create-comment' }">{{ $t('router.create-comment') }}</router-link>
        </div>
        <div v-else>
            {{ $t('comment.offline') }}
        </div>
      </div>
        <div class="comments_container" v-if="isInitialised">
            <Comment
                v-for="(comment, i) in comments"
                :key="i"
                :comment="comment"
            ></Comment>
        </div>
    </main>
</template>

<script>
import { commentLoader } from '../../mixins/commentLoader'
import Comment from './Comment'
import { mapGetters } from 'vuex'

export default {
  name: 'CommentList',
  components: { Comment },
  mixins: [commentLoader],
  computed: {
    ...mapGetters(['isOnline'])
  }
}
</script>

<style lang="scss" scoped>
  .add_comment_container,
  .comments_container{
    margin: $bodyMargin;
  }

  .add_comment_container{
    text-align: center;
    border-top:3px solid $pink;
    padding: $buttonMargin 0;
  }

  .comments_container {
      @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
      }

    @media screen and ($tabletPortrait){
        grid-template-columns: repeat(2, 1fr);

    }

    @media screen and ($desktop){
        grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
