import axios from '../../plugins/axios'

const state = {
  initialised: false,
  localities: [],
  currentLocality: 0
}

const getters = {
  isInitialised: state => {
    return state.initialised
  },

  getAllLocalities: state => {
    return state.localities
  },

  getCurrentLocality: state => {
    return state.localities.find(l => l.id === state.currentLocality)
  }
}

const actions = {
  async loadLocalities ({ commit }) {
    commit('addToLoadingQueue', 'load_localities', { root: true })

    return axios
      .get('/localities')
      .then(response => {
        commit('setLocalities', response.data)
        commit('setInitialised', true)
        commit('removeFromLoadingQueue', 'load_localities', { root: true })
      })
      .catch(error => {
        commit('removeFromLoadingQueue', 'load_localities', { root: true })
        console.log(error)
      })
  },

  async createLocality ({ commit }, formData) {
    commit('addToLoadingQueue', 'create_locality', { root: true })

    return new Promise((resolve, reject) => {
      axios
        // .post("/collections/save/localities", formData, { data: null, headers: { 'Content-Type': false }})
        .post('/localities', formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('removeFromLoadingQueue', 'create_locality', { root: true })
          resolve(response)
        })
        .catch((error) => {
          commit('removeFromLoadingQueue', 'create_locality', { root: true })
          reject(error)
        })
    })
  }
}

const mutations = {
  setInitialised (state, status) {
    state.initialised = status
  },

  setLocalities (state, localities) {
    state.localities = localities
  },

  setCurrentLocality (state, id) {
    state.currentLocality = id
  },

  clearCurrentLocality (state) {
    state.currentLocality = 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
