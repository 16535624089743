<template>
    <div class="map_box">
        <div class="locality-map" ref="googleMap"></div>
        <template v-if="Boolean(this.google) && Boolean(this.map)">
            <slot
                :google="google"
                :map="map"
                :bounds="bounds"
            />
        </template>
    </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import MarkerCluster from '@google/markerclustererplus'
import { mapConfig } from '@/config'

export default {
  name: 'GoogleMapLoader',
  props: {
    mapConfig: Object
  },
  data () {
    return {
      ...mapConfig,
      google: null,
      map: null,
      bounds: null,
      markers: []
    }
  },
  async mounted () {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.mapConfig.mapTypeControlOptions.style = this.google.maps.MapTypeControlStyle.DROPDOWN_MENU
    this.initializeMap()
  },

  methods: {
    initializeMap () {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
      this.bounds = new this.google.maps.LatLngBounds()
    }
  },
  watch: {
    markers: function () {
      this.cluster = new MarkerCluster(this.map, this.markers, {
        styles: [{
          url: require('@/assets/cluster.png'),
          height: 40,
          width: 40,
          textColor: '#ffffff',
          textLineHeight: 40
        }]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .locality-map {
    width: 100%;
    height: 66vh;

    @media screen and (min-height:568px){
      height: 75vh;
    }
  }
</style>
