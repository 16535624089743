<template>
    <div v-if="show" class="install-banner">
        <img src="@/assets/Wildrose.svg" />
        <span>
            {{ $t('system.install.teaser') }}
        </span>
        <div class="buttons_container">
        <button @click="install">{{ $t('system.install.install') }}</button>
        <button @click="show=false">{{ $t('system.close') }}</button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'InstallPrompt',
  data () {
    return {
      show: false,
      event: null
    }
  },
  created () {
    const me = this

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()

      me.event = e
      me.show = true
    })
  },
  methods: {
    install () {
      this.show = false
      this.showInstallBanner = false
      this.event.prompt()
      this.event.userChoice.then(() => {
        this.event = null
      })
    }
  }
}
</script>

<style scoped lang="scss">
    .install-banner {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      background-color: transparentize($pink, 0.2);
      color: #ffffff;
      height: 15vh;

        img {
            width: 40px;
            height: 40px;
            padding: 10px;
            background-color: #ffffff;
            border-radius: 10px;
        }

        button {
            padding: 10px;
            cursor: pointer;
            background-color: #ffffff;
            color: #BA4C80;
            border: none;
            margin-left: 20px;
        }
    }

    .buttons_container{
      display: block;
      margin: 10px 0;
      position: relative;
    }
</style>
