<template>
  <article class="comment">
    <header>
      <div class="person_box">
        {{ comment.name }} | {{ comment.created_at | formatDate }}
      </div>
      <h2>{{ comment.title }}</h2>
    </header>
    <main>
      {{ comment.text }}
    </main>
  </article>
</template>

<script>
export default {
  name: 'Comment',
  props: {
    comment: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .comment{
    border: 1px solid $black;
    padding: $bodyMargin;
    background-color: $white;
    box-shadow: 2px 1px 2px 1px transparentize($black, 0.8);
  }
</style>
