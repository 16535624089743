<template>
    <div class="info_box" v-if="show">
        <button @click="close"></button>
        <div class="reported_box">
            <span class="author">{{ locality.name }}</span><span class="reported">{{ locality.created_at|formatDate}}</span>
        </div>
        <img class="info_img" :src="src"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LocalityInfo',
  data () {
    return {
      show: false,
      src: null
    }
  },
  computed: {
    ...mapGetters({
      locality: 'localities/getCurrentLocality'
    })
  },
  methods: {
    close: function () {
      this.show = false
      this.$store.commit('localities/clearCurrentLocality')
    }
  },
  watch: {
    locality: function (locality) {
      if (locality !== undefined) {
        const loaderKey = 'loading_img_' + locality.id

        this.$store.commit('addToLoadingQueue', loaderKey)

        const img = new Image()
        this.src = img.src = '/images/' + locality.image

        img.onload = function (e) {
          this.$store.commit('removeFromLoadingQueue', loaderKey)
          this.show = true
        }.bind(this)

        img.onerror = function (e) {
          this.show = false

          this.$store.commit('removeFromLoadingQueue', loaderKey)

          this.$store.commit('newNotification', {
            text: 'system.image.error',
            button: 'system.error.dismiss'
          })
        }.bind(this)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .info_box {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;
        margin: 0;
        max-width: 100%;
        max-height: 80vh;
        font-family: $boldFont;
        background-color: $black;

        @media screen and ($tabletPortrait) {
            position: absolute;
            width: 600px;
        }

        button {
            position: absolute;
            display: block;
            right: 30px;
            top: 16px;
            width: 25px;
            height: 25px;
            margin: 0;
            border: none;
            background: none;
            font-size: 16px;
            cursor: pointer;
            transform: rotate(45deg);
            z-index: 11;

          &::before,
          &::after{
            position: relative;
            content: '';
            height: 2px;
            background-color: $white;
            width: 25px;
            display: block;
            transform-origin: center;
          }

          &::before{
            top: 2px;
          }

          &::after{
            transform: rotate(90deg);
          }

            @media screen and ($tabletPortrait) {
                font-size: 18px;
            }

        }

        .info_img {
            display: block;
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }
  .reported_box{
    color: $white;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    font-size: 14px;
    padding: 5px 10px;
    background-color: $pink;
    z-index: 10;

    .author{
      position: relative;
      margin-right: 0.5rem;
      padding-right: 1rem;
      &::after{
        content: '|';
        position: absolute;
        right: 0;
      }
    }
  }

</style>
