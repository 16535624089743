<template>
    <div class="loader-canvas" v-if="isLoading">
        <LoaderImage></LoaderImage>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderImage from './LoaderImage'

export default {
  name: 'Loader',
  components: { LoaderImage },
  computed: {
    ...mapGetters(['isLoading'])
  }
}
</script>

<style scoped lang="scss">
    .loader-canvas {
      position: fixed;
      margin: 0;
      padding: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparentize($pink, 0.3);
      text-align: center;
    }
</style>
