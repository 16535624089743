import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import moment from 'moment'

moment.locale(i18n.locale)

Vue.filter('formatDate', function (val) {
  if (val) {
    return moment(String(val)).format('LLL')
  }
})

Vue.config.productionTip = true

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created () {
    store.commit('onlineStatus', navigator.onLine)
    store.commit('initialisation')

    window.addEventListener('offline', () => {
      store.commit('onlineStatus', false)
    })

    window.addEventListener('online', () => {
      store.commit('onlineStatus', true)
    })
  }
}).$mount('#app')
