<template>
    <section class="comment_section">
        <header class="comments_header">
            <h1>Kommentare zu den Wildrosen</h1>
            <p class="lead">
                Uns interessiert deine Meinung zu den Wildrosen. Machst du Konfi aus Hagebutten? Tee aus Rosenknospen? Oder ärgerst du dich über das stachlige Gestrüpp? Wir freuen uns über jeden Kommentar!
            </p>
        </header>
        <router-view></router-view>
    </section>
</template>

<script>
export default {
  name: 'Comments'
}
</script>

<style lang="scss" scoped>
  .comment_section{
    width: 100%;
    margin: 0 auto;
    max-width: $maxContainerWidth;
  }
  .comments_header{
    margin: $bodyMargin;
  }
</style>
