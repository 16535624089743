import { mapGetters } from 'vuex'

export const commentLoader = {
  data: function () {
    return {
      comments: undefined
    }
  },

  computed: {
    ...mapGetters({
      isInitialised: 'comments/isInitialised',
      getAllComments: 'comments/getAllComments'
    })
  },

  created () {
    this.loadComments()
  },

  methods: {
    loadComments () {
      if (this.isInitialised) {
        this.comments = this.getAllComments
      } else {
        this.$store.dispatch('comments/loadComments').then(() => {
          this.comments = this.getAllComments
        })
      }
    }
  }
}
