<template>
    <GoogleMapLoader
        :mapConfig="mapConfig"
    >
        <template slot-scope="{ google, map, bounds }">
            <GoogleMapMarker
                    v-for="locality in localities"
                    :key="locality.id"
                    :locality="locality"
                    :google="google"
                    :map="map"
                    :bounds="bounds"
                    @openLocality="openLocality "
            />
        </template>
    </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from '../Map/GoogleMapLoader'
import GoogleMapMarker from '../Map/GoogleMapMarker'
import { localityLoader } from '../../mixins/localityLoader'
import { mapSettings } from '../../config'

export default {
  name: 'LocalityMap',
  components: { GoogleMapMarker, GoogleMapLoader },
  mixins: [localityLoader],

  computed: {
    mapConfig () {
      return {
        ...mapSettings,
        center: this.mapCenter
      }
    },

    mapCenter () {
      return { lat: parseFloat(this.localities[0].location.lat), lng: parseFloat(this.localities[0].location.lng) }
    }
  },

  methods: {
    openLocality (id) {
      this.$store.commit('localities/setCurrentLocality', id)
    }
  }
}
</script>

<style scoped>

</style>
