export const mapConfig = {
  apiKey: 'AIzaSyDGWd3lvHN_JOHRgdpSBm4164BbwczFq2Q'
}

export const mapSettings = {
  zoom: 12,
  center: { lat: 46.9390187, lng: 8.345401 },
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: null,
    mapTypeIds: ['satellite', 'terrain']
  },
  mapTypeId: 'satellite',
  restriction: {
    // Nid- & Obwalden bounds
    latLngBounds: {
      west: 8.03,
      east: 8.577,
      south: 46.746694561949276,
      north: 47.005
    },
    strictBounds: false
  }
}
