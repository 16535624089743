const state = {
  curPosition: null
}

const getters = {
  getCurPosition (state) {
    return state.curPosition
  }
}

const actions = {}

const mutations = {
  setCurPosition (state, { lat, lng }) {
    state.curPosition = { lat: parseFloat(lat), lng: parseFloat(lng) }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
