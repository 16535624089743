import axios from '../../plugins/axios'

const state = {
  initialised: false,
  comments: []
}

const getters = {
  isInitialised: state => {
    return state.initialised
  },
  getAllComments: state => {
    return state.comments
  }
}

const actions = {
  async loadComments ({ commit }) {
    commit('addToLoadingQueue', 'load_comments', { root: true })

    return axios
      .get('/comments')
      .then(response => {
        commit('setComments', response.data)
        commit('setInitialised', true)
        commit('removeFromLoadingQueue', 'load_comments', { root: true })
      })
      .catch(() => {
        commit('removeFromLoadingQueue', 'load_comments', { root: true })
      })
  },

  async createComment ({ commit }, comment) {
    commit('addToLoadingQueue', 'create_comment', { root: true })

    return new Promise((resolve, reject) => {
      axios
        .post('/comments', comment)
        .then((response) => {
          commit('removeFromLoadingQueue', 'create_comment', { root: true })
          resolve(response)
        })
        .catch((error) => {
          commit('removeFromLoadingQueue', 'create_comment', { root: true })
          reject(error)
        })
    })
  }
}

const mutations = {
  setInitialised (state, status) {
    state.initialised = status
  },

  setComments (state, comments) {
    state.comments = comments
  },

  addComment (state, comment) {
    state.comments.push(comment)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
