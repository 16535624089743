<template>
  <div class="app">
    <header class="site_header">
      <div class="header_padding">
        <h1>
          <img src="@/assets/Wildrose.svg" alt="Wildrosenprojekt | Naturforschende Gesellschaft Ob- und Nidwalden NAGON" /><span>Wildrosen</span>
        </h1>
        <nav id="nav">
          <router-link class="button" :to="{ name: 'localities' }">{{ $t('router.localities-root') }}</router-link>
          <router-link class="button" :to="{ name: 'comments' }">{{ $t('router.comments-root') }}</router-link>
          <router-link class="button" :to="{ name: 'faq' }">{{ $t('router.faq-root') }}</router-link>
          <a class="button" rel="noreferrer" href="https://naturwissenschaften.ch/organisations/nagon/aktuell" target="_blank">{{ $t('router.project-root')}}</a>
        </nav>
      </div>
    </header>
    <main class="site_main">
      <router-view></router-view>
      <Loader></Loader>
      <Prompt></Prompt>
      <InstallPrompt></InstallPrompt>
    </main>
  </div>
</template>

<script>
import Prompt from './components/system/Prompt'
import Loader from './components/system/Loader'
import InstallPrompt from './components/system/InstallPrompt'

export default {
  components: { InstallPrompt, Loader, Prompt }
}
</script>

<style lang="scss">
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: $regularFont;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .app{
    width: inherit;
    height: 100vh;
    max-height: 100%;
    @supports (display: grid) {
      display: grid;
      grid-template-rows: auto 1fr;
    }
  }

  .site_header {
    width: inherit;
    max-width: $maxContainerWidth;
    margin: 0 auto;

    .header_padding{
      margin: 0 $bodyMargin;
      @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
      }
      @media screen and ($desktop) {
        grid-template-columns: auto 1fr;
        align-items: center;
        justify-items: flex-end;
      }
    }
    img {
      position: relative;
      max-width: 30px;
      margin-right: 5px;

      @media screen and ($tabletPortrait) {
        max-width: 60px;
        margin-right: 10px;
      }
    }
    h1{
      position: relative;
      padding: $buttonMargin 0 0 0;
      margin: 0;
      line-height: 1.6em;
      font-size: 1rem;

      @media screen and ($tabletPortrait) {
        font-size: 2rem;
      }

      span{
        float: right;
      }
    }

    #nav{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      justify-content: space-around;
      width: 100%;
      max-width: 100%;
      margin: 10px 0;

      @media screen and ($tabletPortrait) {
        width: unset;
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and ($desktop) {
        justify-content: flex-end;
      }

      a{
        text-align: center;
        margin: 0;

        @media screen and ($tabletPortrait) {
          margin: 5px 5px;
        }
      }
    }

    a {
      display: inline-block;
      font-family: $boldFont;
      color: $white;
      text-transform: uppercase;
      transition: color 200ms linear;
      font-size: 0.55rem;
      padding: 5px;

      @media screen and ($smallMobilePortrait) {
        font-size: 0.65rem;
        padding: 5px 10px;
      }

      @media screen and ($mobilePortrait) {
        font-size: 0.75rem;
      }

      @media screen and ($tabletPortrait) {
        font-size: 1rem;
      }

      &.router-link-exact-active,
      &:hover{
        background-color: lighten($pink, 40%);
        color: $pink;
      }
    }
  }

  .site_main{
    max-width: $maxContainerWidth;
    margin: 0 auto;
    width: inherit;
    position: relative;

    >div{
      width: inherit;
      height: 100%;
    }
  }

  @keyframes click_effect {
    50% {
      transform: scale(1.5, 1.5) translateY(-40px) translateX(-40px);
      opacity: 0;
    }
    99% {
      transform: scale(0.001, 0.001) translateY(-40px) translateX(-40px);
      opacity: 0;
    }
    100% {
      transform: scale(0.001, 0.001) translateY(-40px) translateX(-40px);
      opacity: 1;
    }
  }

  input, textarea, button[type="submit"]{
    border: none;
    background-color: transparentize($black, 0.8);
    padding: 0.5rem;
    width: 285px;
    max-width: 100%;
    margin: 0.25rem 0;
    font-family: $regularFont;
    font-size: 14px;

    @media screen and($mobilePortrait) {
      width: 380px;
    }
    @media screen and($tabletPortrait) {
      width: 480px;
    }
  }

  button[type="submit"]{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid $pink;
    margin: 1rem auto;
    font-family: $boldFont;
    background-color: $pink;
    color: $white;
    text-transform: uppercase;
    width: 285px;
  }
  .gps_button,
  .button{
    color: $white;
    text-transform: uppercase;
    padding: 10px;
    font-family: $boldFont;
    position: relative;
    text-decoration: none;
    transition: all 200ms ease-in;
    overflow: hidden;
    display: inline-block;
    background: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;

    &:before {
      content: '';
      background-color: $white;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 80px;
      transform:  translateY(-40px) translateX(-40px) scale(0.001, 0.001);
    }

    &::after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $pink;
      z-index: -1;
      transition: max-width 200ms;
    }

    &:hover{
      color: $white;
      &::after{
        max-width: 125%;
      }
    }

    &:focus {
      outline: 0;
      &:before {
        animation: click_effect 0.8s ease-out;
      }
    }
  }

  .mapbox_container{
    margin-top: 1rem;
    .map_box,
    .locality-map{
        height: 300px;
        > div{
          height: 300px;
        }
      }
    }

    .lead{
      font-size: .9rem;
      line-height: 1.4em;

      @media screen and ($tabletPortrait) {
        font-size: 1.2rem;
        max-width: 700px;
      }
    }
</style>
