<template>
    <div class="notification" v-if="show">
        <div>
            {{ $t(notification.text)}}
          <div class="buttons_container">
            <button @click="action">{{ $t(notification.button)}}</button>
            <button @click="dismiss" v-if="notification.cancel">{{ $t('system.prompt.cancel') }}</button>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Prompt',
  data: function () {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters(['notification'])
  },
  methods: {
    action () {
      if (!this.notification.onClick) {
        this.dismiss()
      } else {
        this.notification.onClick()
      }
    },
    dismiss () {
      this.show = false
    }
  },
  watch: {
    notification: function (notification) {
      this.show = notification.show

      if (notification.timeout > 0) {
        window.setTimeout(this.dismiss, notification.timeout)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notification {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    background-color: transparentize($pink, 0.2);
    color: #ffffff;
    height: 15vh;

    button {
        padding: 10px;
        cursor: pointer;
        background-color: #ffffff;
        color: #BA4C80;
        border: none;
        margin-left: 20px;
    }

  .buttons_container{
    display: block;
    margin: 10px 0;
    position: relative;
  }
}
</style>
